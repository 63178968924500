<template>
  <!-- 인피니트 리스트 -->
  <vue-infinite-loader
    :fetch="fetch"
    :identifier="identifier"
    item-key="uniqueKey"
    item-text="printerFullName"
    :loading="loading"
    :valid="(e) => e.raw.printerModelName && e.raw.printerModelName.trim()"
    :search-input="searchInput"
  >
    <template #default="{ item }">
      <printer-item-simple-card
        :item="item"
        @pick="pick => $emit('pick', pick)"
      />
    </template>
  </vue-infinite-loader>
</template>

<script>
    import PrinterItemSimpleCard from "@/components/items/printer/PrinterItemSimpleCard";

    export default {
        name: "MFormPrinterList",
        components: {PrinterItemSimpleCard},
        props: {
            items: {
                type: Array,
                default: () => [],
            },
            identifier: {
                type: Number,
                default: 0,
            },
            loading: {
                type: Boolean,
                default: false,
            },
            searchInput: {
                type: String,
                default: "",
            },
        },
        methods: {
            async fetch(pageNumber, pageSize) {
                return this.items.slice((pageNumber - 1) * pageSize, (pageNumber * pageSize) - 1);
            },
        },
    }
</script>

<style scoped>

</style>